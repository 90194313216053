<template>
  <div>
    <b-row>
      <b-col>
        <b-card-title>Product gallery</b-card-title>
      </b-col>

      <b-col cols="auto">
        <b-button @click="$refs.file.click()" :disabled="$cannot('product.create')" variant="primary">
          <input
            style="display:none;"
            type="file"
            ref="file"
            @change="pick($event)"
            accept="image/*"
            :disabled="$cannot('product.create')"
          />
          Pick Image
        </b-button>

        <b-modal id="addImage" title="Add Image" size="lg" hide-footer>
          <div class="nav-wizards-container">
            <b-nav class="nav-wizards-2 mb-2">
              <b-nav-item
                v-for="(item, key, index) in sizes"
                class="col"
                :key="key"
                :link-classes="stepClass(index + 1)"
              >
                {{ item.name }}
              </b-nav-item>
            </b-nav>
          </div>

          <div v-for="({ width, height }, key, index) in sizes" :key="key">
            <b-card v-if="currentStep === index + 1">
              <cropper
                :ref="`cropper-${index}`"
                :src="thumbnail"
                :stencil-props="{
                  handlers: {},
                  movable: false,
                  scalable: false,
                }"
                :stencil-size="{
                  width: width,
                  height: height,
                }"
                image-restriction="stencil"
                @change="args => cropperChange(key, args)"
              />
            </b-card>
          </div>

          <b-row align-h="end" class="mt-4">
            <b-col cols="auto">
              <b-button v-if="!firstStep" class="mr-2" variant="link" @click="previousStep">
                Back
              </b-button>

              <b-button v-if="!lastStep" variant="primary" @click="nextStep">
                Next
              </b-button>

              <b-button v-if="lastStep" variant="primary" :disabled="$cannot('product.create') || isLoading" @click="upload">
                Crop and Upload
                <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
      </b-col>
    </b-row>

    <hr />

    <draggable v-model="items" class="row" @end="setNewOrder">
      <b-col v-for="(item, index) in items" :key="index" cols="3">
        <b-button-close @click="deleteItem(item)" />
        <!-- <b-img
          :src="`https://picsum.photos/seed/${item.id}/300/200.webp`"
          :alt="`${gallery.name} item #${index + 1}`"
          fluid
        /> -->

        <b-img :src="item.small_url" fluid />
      </b-col>
    </draggable>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import Draggable from 'vuedraggable'
import { products } from '@/api'

export default {
  name: 'ProductImagesGallery',
  inject: ['$cannot'],
  components: { Cropper, Draggable },
  props: { product: Object },
  data() {
    return {
      gallery: null,
      items: [],
      thumbnail: '',
      blob: null,
      cropSizes: {},
      currentStep: 1,
      isLoading: false
    }
  },
  computed: {
    firstStep() {
      return this.currentStep === 1
    },

    lastStep() {
      return this.currentStep === this.totalSteps
    },

    totalSteps() {
      return Object.keys(this.sizes).length
    },

    sizes() {
      return {
        standard: { name: 'Standard', width: 675, height: 675 },
        double: { name: 'Large', width: 675 * 2, height: 375 * 2 },
      }
    },
  },
  watch: {
    product() {
      this.preload()
    },
  },
  beforeMount() {
    this.preload()
  },
  methods: {
    async preload() {
      this.items = this.product.gallery
    },

    pick(event) {
      // Reference to the DOM input element
      const input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        const reader = new FileReader()
        reader.addEventListener('load', event => {
          this.thumbnail = event.target.result

          this.$bvModal.show('addImage')
          this.blob = input.files[0]
        })

        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },

    stepClass(step) {
      if (this.currentStep > step) {
        return 'completed'
      }

      return step === this.currentStep ? 'active' : 'disabled'
    },

    previousStep() {
      if (this.currentStep <= 1) {
        return
      }

      this.currentStep = this.currentStep - 1
    },

    nextStep() {
      if (this.currentStep === this.totalSteps) {
        return
      }

      this.currentStep = this.currentStep + 1
    },

    cropperChange(key, { coordinates }) {
      this.cropSizes[key] = coordinates
    },

    async upload() {
      this.isLoading = true;

      await products.gallery(this.product.id).addImage(
        { image: this.blob },
        {
          'X-Youwish-Gallery-Custom-Sizes': JSON.stringify(this.cropSizes),
        }
      )

      this.$bvModal.hide('addImage')
      this.$bvToast.toast(`Item added successfully added to gallery.`, {
        title: 'Image uploaded',
        variant: 'primary',
      })

      this.isLoading = false;

      this.$emit('updated')
      this.preload()
    },

    async deleteItem(item) {
      await products.gallery(this.product.id).deleteImage({ image_id: item.id })
      this.$emit('updated')
      this.preload()
    },

    async setNewOrder() {
      const order = this.items.map(item => item.id)

      await products.gallery(this.product.id).reorder(order)
      this.$emit('updated')

      this.preload()
    },
  },
}
</script>
